import { api } from "@/store/api";
import { Agent } from "@/types/agent";

const ENDPOINT_PREFIX = "agent";

export const agentApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getAgents: builder.query<Agent[], { includeFormAgents: boolean } | void>({
            query: (arg) => {
                const { includeFormAgents } = arg || {};
                return {
                    url: ENDPOINT_PREFIX,
                    method: "GET",
                    params: {
                        include_form_agents: includeFormAgents,
                    },
                };
            },
        }),
    }),
});

export const { useGetAgentsQuery } = agentApi;
