import { Sidebar as WdsSidebar } from "@datamole/wds-component-sidebar";
import { clsx } from "clsx";
import { useState } from "react";

import { Agents } from "@/components/layout/sidebar/agents";
import { Header } from "@/components/layout/sidebar/header";
import { SidebarItem } from "@/components/layout/sidebar/sidebar-item";
import { sidebarItems } from "@/components/layout/sidebar/sidebar-items";
import styles from "@/components/layout/sidebar/sidebar.module.css";
import { useScrollToSectionEffect } from "@/components/layout/sidebar/use-scroll-to-section-effect";
import { UserMenu } from "@/components/layout/sidebar/user-menu/user-menu";
import { useGetAgentsQuery } from "@/store/services/agent.service";

export const Sidebar = () => {
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
    const [agentsCollapsed, setAgentsCollapsed] = useState(false);
    const { data: agents = [], error: agentsError } = useGetAgentsQuery();

    const toggleSidebarCollapsed = () => {
        setSidebarCollapsed((prev) => !prev);
    };

    const handleAgentClicked = () => {
        if (sidebarCollapsed) {
            setSidebarCollapsed(false);
            setAgentsCollapsed(false);
        } else {
            setAgentsCollapsed((prev) => !prev);
        }
    };

    if (agentsError) {
        // TODO: handle error properly
        console.error("Failed to fetch agents", agentsError);
    }

    useScrollToSectionEffect();

    return (
        <WdsSidebar className={clsx(styles.sidebar, { [styles.collapsed]: sidebarCollapsed })}>
            <WdsSidebar.Content>
                <Header sidebarCollapsed={sidebarCollapsed} toggleSidebarCollapsed={toggleSidebarCollapsed} />

                <SidebarItem {...sidebarItems.home} sidebarCollapsed={sidebarCollapsed} />
                <div
                    onClick={handleAgentClicked}
                    onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                            handleAgentClicked();
                        }
                    }}
                    role="button"
                    tabIndex={-1}
                >
                    <SidebarItem
                        {...sidebarItems.agents}
                        sidebarCollapsed={sidebarCollapsed}
                        className={styles.inactivable}
                    />
                </div>

                <div
                    className={clsx(styles.submenu, {
                        [styles.submenu_collapsed]: agentsCollapsed || sidebarCollapsed,
                    })}
                >
                    <Agents agents={agents} />
                </div>

                <SidebarItem
                    {...sidebarItems.chatHistory}
                    sidebarCollapsed={sidebarCollapsed}
                    className={styles.inactivable}
                />

                <SidebarItem {...sidebarItems.trialEvents} sidebarCollapsed={sidebarCollapsed} />

                <SidebarItem {...sidebarItems.scientificTestingCalculate} sidebarCollapsed={sidebarCollapsed} />
            </WdsSidebar.Content>
            <WdsSidebar.Content>
                <UserMenu />
            </WdsSidebar.Content>
        </WdsSidebar>
    );
};
