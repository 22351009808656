// Dates
export const API_DATE_FORMAT = "YYYY-MM";
export const FULL_MONTH_YEAR_DATE_FORMAT = "MMMM YYYY";
export const MONTH_PICKER_DATE_FORMAT = "YYYY-MM";
export const DATE_PICKER_DATE_FORMAT = "YYYY-MM-DD";

export const DISPLAY_DATE_FORMAT = "DD-MM-YYYY";

// URL Params
export const AGENT_ID_PARAM_KEY = "agent-id";
export const SCROLL_TO_PARAM_KEY = "scroll-to";

// Section Ids
export const HOMEPAGE_CHAT_ID = "chat";
export const HOMEPAGE_CHAT_HISTORY_ID = "chat-history";
