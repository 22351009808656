import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PromptState {
    text: string | null;
}

const INITIAL_PROMPT_STATE: PromptState = {
    text: null,
};

const promptSlice = createSlice({
    name: "prompt",
    initialState: INITIAL_PROMPT_STATE,
    reducers: {
        setPrompt: (state, action: PayloadAction<PromptState>) => {
            state.text = action.payload.text;
        },
        clearPrompt: () => INITIAL_PROMPT_STATE,
    },
});

export const { setPrompt, clearPrompt } = promptSlice.actions;
export const promptReducer = promptSlice.reducer;
