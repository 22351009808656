import React from "react";

import { chatGreen, chatLightBlue, chatProfile, menu, menuClose, message } from "@/components/svg-icon/icons";

type SvgIconName = "chatGreen" | "chatLightBlue" | "chatProfile" | "menu" | "menuClose" | "message";

const icons: Record<SvgIconName, string> = {
    chatGreen,
    chatLightBlue,
    chatProfile,
    menu,
    menuClose,
    message,
};

interface SvgIconProps {
    iconName: SvgIconName;
    alt?: string;
    className?: string;
}

export const SvgIcon: React.FC<SvgIconProps> = ({ iconName, alt = "", className }: SvgIconProps) => {
    const svgIcon = icons[iconName];

    return <img src={svgIcon} alt={alt} className={className} />;
};
