import { Link } from "react-router-dom";

import styles from "@/components/error-fallback/error-fallback.module.css";
import { routes } from "@/routes";

/**
 * Error fallback component to be rendered by an error boundary in case it encounters any error
 *
 * @returns rendered error fallback
 */
export const ErrorFallback = () => {
    return (
        <div className={styles.error_fallback}>
            <h1>Something went wrong :(</h1>
            <Link to={routes.home.path}>Go to homepage</Link>
        </div>
    );
};
